/*
  Authors : bunchdevelopers (Rahul Jograna)
  Website : https://bunchdevelopers.com/
  App Name : ionic6Template Pack
  This App Template Source code is licensed as per the
  terms found in the Website https://bunchdevelopers.com/license
  Copyright and Good Faith Purchasers © 2021-present bunchdevelopers.
*/
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: "regular";
  src: url(assets/fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "medium";
  src: url(assets/fonts/Roboto-Medium.ttf);
}
@font-face {
  font-family: "bold";
  src: url(assets/fonts/Roboto-Bold.ttf);
}

* {
  font-family: "regular";
}

.content_button {
  height: 40px;
  --background: var(--ion-color-primary);
  --border-radius: 30px;
  font-family: "regular";
  letter-spacing: 0.7px;
  margin: 0px 40px;
}

ion-content {
  letter-spacing: 0.7px;
  font-family: "regular";
}

ion-header {
  ion-toolbar {
    // margin-top: 15px;
    --border-style: 0px;
    --background: #ffff;
    font-family: "regular";
    letter-spacing: 0.7px;
    ion-buttons {
      ion-button {
        --color: var(--ion-color-primary);
      }
    }
  }
}

ion-footer {
  ion-toolbar {
    --border-style: 0px;
    --background: #ffff;
    font-family: "regular";
    letter-spacing: 1px;
    font-weight: normal;
    .content_button {
      height: 40px;
      --background: var(--ion-color-primary);
      --border-radius: 30px;
      font-family: "regular";
      letter-spacing: 0.7px;
      margin: 0px 40px 20px 40px;
    }
  }
}

.heading {
  font-size: 20px;
  font-family: "bold";
  display: block;
  margin: 15px 0px;
}

.sub_heading {
  font-size: 17px;
  font-family: "regular";
  display: block;
  margin: 15px 0px;
}

.small_heading {
  font-size: 10px;
  font-family: "regular";
  display: block;
  margin: 15px 0px;
  color: #92949c;
}

ion-label {
  font-family: "regular";
}

ion-item {
  ion-label {
    font-family: "regular";
  }
  ion-select {
    font-family: "regular";
  }
  ion-input {
    font-family: "regular" !important;
  }
}

ion-modal.custom_modal {
  border-radius: 20px 20px 0px 0px;
  top: 50vh;
  box-shadow: 0px 0px 20px var(--box-color);
  --box-color: #d7d8da;
  .modal-wrapper {
    width: 100% !important;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
}
